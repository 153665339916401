import { LinkText } from '@mediahuis/chameleon-react';
import base from './base';

const language = {
  ...base,
  [LANG.NL]: {
    ...base[LANG.NL],
    AboPolicyDefault: () => (
      <>
        Ik ga akkoord met de{' '}
        <LinkText
          href="https://limburger.nl/actievoorwaarden"
          target="_blank"
          style={{ textDecoration: 'underline ' }}
        >
          Actievoorwaarden
        </LinkText>{' '}
        en{' '}
        <LinkText
          href="https://www.limburger.nl/algemenevoorwaarden/"
          target="_blank"
          style={{ textDecoration: 'underline ' }}
        >
          Algemene voorwaarden.
        </LinkText>
      </>
    ),
    AboPolicyOneShot: () => (
      <>
        Ik ga akkoord met de{' '}
        <LinkText
          href="https://limburger.nl/actievoorwaarden"
          target="_blank"
          style={{ textDecoration: 'underline ' }}
        >
          Actievoorwaarden
        </LinkText>{' '}
        en{' '}
        <LinkText
          href="https://www.limburger.nl/algemenevoorwaarden/"
          target="_blank"
          style={{ textDecoration: 'underline ' }}
        >
          Algemene voorwaarden.
        </LinkText>
      </>
    ),
    AboPolicyRecurring: () => (
      <>
        Ik ga akkoord met de{' '}
        <LinkText
          href="https://limburger.nl/actievoorwaarden"
          target="_blank"
          style={{ textDecoration: 'underline ' }}
        >
          Actievoorwaarden
        </LinkText>{' '}
        en{' '}
        <LinkText
          href="https://www.limburger.nl/algemenevoorwaarden/"
          target="_blank"
          style={{ textDecoration: 'underline ' }}
        >
          Algemene voorwaarden
        </LinkText>{' '}
        en ik machtig Mediahuis Limburg om het abonnementsgeld tot
        wederopzegging periodiek van mijn rekening af te schrijven.
      </>
    ),
  },
};

export default language;
