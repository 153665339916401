import base from './base';

const language = {
  ...base,
  [LANG.NL]: {
    ...base[LANG.NL],
    checkout20Id: 'betaalwijze',
    settlement20Id: 'betaalwijze',
  },
};

export default language;
