import base from './base';

const language = {
  ...base,
  [LANG.NL]: {
    ...base[LANG.NL],
    imageAlt: 'uploaded',
    selectFile: `Sleep hier je bestand naartoe of klik om toe te voegen`,
    fileExtensions: '(JPG, JPEG, PNG of PDF - max. 15MB)',
    lengthError: 'Het bestand mag maximaal 15MB zijn.',
    fileTypeError:
      'Ongeldig bestandstype. Wij ondersteunen JPG, JPEG, PNG en PDF.',
  },
};

export default language;
