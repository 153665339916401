import base from './base';

const language = {
  ...base,
  [LANG.NL]: {
    ...base[LANG.NL],
    contactCustomerService: 'Neem contact op met de klantenservice',
    footerTitle: 'Hulp nodig?',
    footerContactPhoneDesktop:
      'Neem telefonisch contact op met onze klantenservice via het nummer',
    footerContactPhoneClosingHourDesktop:'Bereikbaar op maandag, dinsdag, donderdag en vrijdag van 08:00 tot 18:00 \n Woensdag van 08:00 tot 20:00 en zaterdag van 08:00 tot 12:00.',
    privacyPolicy: 'Privacybeleid',
    customerServicePhoneNumber: '088 77 80 800',
    privacyPolicyUrl: 'https://www.mediahuislimburg.nl/privacypolicy',
    addressLine1: 'Wim Duisenbergplantsoen 1',
    addressLine2: '6221 SE - Maastricht',
    name: 'Mediahuis Limburg B.V.',
    btwNumber: 'BTW-nummer: NL854228147B01',
    kvkNumber: 'KVK-nummer: 61146315',
    terms: 'Algemene voorwaarden',
    termsLink: 'https://www.limburger.nl/algemenevoorwaarden',
  },
};

export default language;
