import base from './base';

export const BANNERS = { ...base.BANNERS };

export const PAYMENTS = {
  ...base.PAYMENTS,
  [LANG.NL]: {
    ...base.PAYMENTS[LANG.NL],
    FAILED:
      'Er ging helaas iets mis met de afhandeling van de betaling. Probeer het a.u.b. opnieuw.',
    CANCELED: 'Probeer het a.u.b. opnieuw of kies een andere betaalwijze.',
    EXPIRED:
      'De sessie is verlopen. Probeer opnieuw of kies een andere betaalwijze.',
  },
};

export const ERRORS = {
  ...base.ERRORS,
  [LANG.NL]: {
    ...base.ERRORS[LANG.NL],
    InvalidIban: 'Bankrekeningnummer (IBAN) is ongeldig',
    FileUpload: `Er ging iets mis met de afhandeling van je bestand. Probeer het a.u.b. opnieuw of kies een ander bestand.`,
  },
};

export const WARNINGS = {
  ...base.WARNINGS,
  [LANG.NL]: {
    ...base.WARNINGS[LANG.NL],
    OFFER_INVALID: `Het aanbod dat je gekozen hebt is niet meer beschikbaar. Kies een ander abonnement.`,
    OFFER_INVALID_RENEWAL: `Het aanbod dat je gekozen hebt werd reeds geactiveerd. Kies een ander abonnement.`,
    GENERAL: `Er ging iets mis met de afhandeling van je gekozen abonnement. Probeer het a.u.b. opnieuw of kies een ander abonnement.`,
    SETTLEMENT_INVALID:
      'De betaallink is niet meer beschikbaar. Wellicht heb je al betaald? Zo niet, neem dan contact op met onze klantenservice.',
  },
};
