import { LinkText, Paragraph } from '@mediahuis/chameleon-react';
import base from './base';

export const language = {
  ...base,
  [LANG.NL]: {
    ...base[LANG.NL],
    Subtitle: props => (
      <Paragraph {...props}>
        Beste klant, bedankt dat je interesse hebt in onze nieuwsmerken.
        Momenteel zijn we druk aan het werk om je later nog beter van dienst te
        kunnen zijn.
        <br />
        <br />
        <LinkText
          href="https://klantenservice.limburger.nl/contact"
          target="_blank"
        >
          Laat hier je gegevens achter, we contacteren je spoedig.
        </LinkText>
      </Paragraph>
    ),
  },
};
